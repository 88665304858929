<template>
  <div>
    <Toolbar class="mb-4">
      <template #left>
        <Button
          label="Get All"
          icon="pi pi-eye"
          class="ml-2 p-button-success"
          @click="getAll()"
          v-if="showAll == 0"
        />
        <Button
          v-else
          label="Get Done Only"
          icon="pi pi-eye"
          @click="getAll()"
          class="p-button-danger"
        />
      </template>

      <template #right>
        <!-- <Button icon="pi pi-cog" class="p-button-basic ml-2" /> -->
        <Button
          icon="pi pi-download"
          class="p-button-help"
          @click="exportCSV($event)"
        />
        <!-- <Button icon="pi pi-search" class="ml-2" />
        <Button icon="pi pi-times" class="p-button-danger" /> -->
      </template>
    </Toolbar>

    <DataTable
      ref="dt"
      :value="list"
      :paginator="true"
      class="p-datatable-customers"
      :rows="10"
      dataKey="id"
      :rowHover="true"
      :selection.sync="selectedItems"
      :expandedRows.sync="expandedRows"
      :loading="loading"
      :filters="filters"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50, 100]"
      currentPageReportTemplate="show {first} to {last} from {totalRecords}"
    >
      <template #header>
        <div class="table-header">
          Kontaktiere uns Aufführen

          <div style="text-align: left">
            <!-- <MultiSelect
              :value="selectedColumns"
              :options="columns"
              optionLabel="header"
              @input="onToggle"
              placeholder="Select Columns"
              style="width: 20em"
            /> -->
          </div>
        </div>
      </template>
      <template #empty>Keine Daten</template>
      <template #loading>Daten werden geladen, bitte warten... </template>

      <Column :expander="true" headerStyle="width: 3rem" />

      <Column
        filterMatchMode="contains"
        field="id"
        header="ID"
        :sortable="true"
      >
        <template #filter>
          <InputText
            type="number"
            v-model="filters['id']"
            class="p-column-filter"
            placeholder="Search By ID"
          />
        </template>

        <template #body="slotProps">
          <p v-if="slotProps.data.messageReply" style="color: green">
            {{ slotProps.data.id }}
          </p>
          <p v-else style="color: crimson">
            {{ slotProps.data.id }}
          </p>
        </template>
      </Column>
      <Column
        filterMatchMode="contains"
        field="pname"
        header="Name"
        :sortable="true"
      >
        <template #filter>
          <InputText
            v-model="filters['pname']"
            class="p-column-filter"
            filterMatchMode="contains"
            placeholder="Search By Name"
          />
        </template>
      </Column>

      <Column
        filterMatchMode="contains"
        field="phone"
        header="Telefon"
        :sortable="true"
      >
        <template #filter>
          <InputText
            v-model="filters['phone']"
            class="p-column-filter"
            placeholder="Search By Telefon"
          />
        </template>
      </Column>
      <Column
        filterMatchMode="contains"
        field="email"
        header="Email"
        :sortable="true"
      >
        <template #filter>
          <InputText
            type="email"
            v-model="filters['email']"
            class="p-column-filter"
            placeholder="Search By Email"
          />
        </template>
      </Column>

      <Column
        filterMatchMode="contains"
        field="subject"
        header="Subject"
        :sortable="true"
      >
        <template #filter>
          <InputText
            type="subject"
            v-model="filters['subject']"
            filterMatchMode="contains"
            class="p-column-filter"
            placeholder="Search By Subject"
          />
        </template>
      </Column>

      <Column
        filterMatchMode="contains"
        field="createdAt"
        header="Date"
        :sortable="true"
      >
        <template #filter>
          <InputText
            type="date"
            v-model="filters['createdAt']"
            class="p-column-filter"
            placeholder="Search By Date"
          />
        </template>
        <template #body="slotProps">
          {{ $durationFormatFullDate(slotProps.data.createdAt) }}
        </template>
      </Column>

      <template #expansion="slotProps">
        <div class="orders-subtable">
          <DataTable
            :value="[slotProps.data]"
            class="p-datatable-customers table table-striped"
          >
            <template #empty>Keine Daten</template>

            <Column
              filterMatchMode="contains"
              field="message"
              header="Message"
            />
          </DataTable>

          <DataTable
            :value="[slotProps.data]"
            v-if="slotProps.data.messageReply"
            class="p-datatable-customers table table-striped"
          >
            <template #empty>Keine Daten</template>

            <Column
              filterMatchMode="contains"
              field="messageReply"
              header="Message Reply"
            />
          </DataTable>
          <Button
            v-else
            label="Colose"
            @click="openSupportTicket(slotProps.data)"
            icon="pi pi-times"
            v-if="!slotProps.data.done"
            class="ml-2 p-button-danger"
          />
          <Button
            v-else
            label="Add Reply"
            @click="openDialog(slotProps.data)"
            icon="pi pi-plus"
            class="ml-2 p-button-success"
            v-if="!slotProps.data.done"
          />
        </div>
      </template>
    </DataTable>

    <Dialog
      header="Add Price"
      :visible.sync="displayProductsAdd"
      :style="{ width: '50vw' }"
    >
      <div class="p-m-0">
        <div class="mb-3">
          <label for="messageReply" class="form-label required">
            Message Reply
            <span>*</span>
          </label>
          <textarea class="form-control" v-model="d.messageReply" />
        </div>
      </div>
      <template #footer>
        <Button
          label="Hinzufügen"
          icon="pi pi-check"
          @click="addProducts()"
          autofocus
        />
        <Button
          label="Colose"
          icon="pi pi-times"
          @click="cDialog()"
          class="p-button-text"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      selectedItems: [],

      expandedRows: [],
      loading: true,
      filters: {},
      displayProductsAdd: false,
      d: {
        messageReply: null,
      },
      showAll: 0,
    };
  },
  methods: {
    getAll() {
      this.showAll = this.showAll == 1 ? 0 : 1;
      this.getData();
    },

    getData() {
      this.$http.get(`contactUs?done=${this.showAll}`).then(
        (response) => {
          this.loading = false;
          this.list = response.data.data;
        },
        (err) => {
          this.loading = false;
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err.response.data.message,
            life: 3000,
          });
        }
      );
    },
    addProducts() {
      if (this.d.messageReply) {
        this.displayProductsAdd = false;
        this.$http.put(`contactUs/${this.d.id}`, this.d).then(
          (response) => {
            this.d = {
              messageReply: null,
            };
            this.getData();
            this.$toast.add({
              severity: "success",
              summary: "Success",
              detail: "Data Added Successfully",
              life: 3000,
            });
          },
          (err) => {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: err.response.data.message,
              life: 3000,
            });
          }
        );
      }
    },
    openDialog(data) {
      this.displayProductsAdd = true;
      this.d = { ...data };
    },
    cDialog() {
      this.displayProductsAdd = false;
      this.d = {
        id: null,
        messageReply: null,
        name: null,
        subject: null,
      };
    },
    onToggle(value) {
      this.selectedColumns = this.columns.filter((col) => value.includes(col));
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },

    deleteItem(id) {
      this.$confirm.require({
        message: "Are you sure you want to delete records?",
        header: "Yes Delete Record",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.$http.delete(`contact/${id}`).then(
            () => {
              this.getData();
              this.$toast.add({
                severity: "error",
                summary: "Done Successfully",
                detail: "Deleted successfully",
                life: 3000,
              });
            },
            (err) => {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: err.response.data.message,
                life: 3000,
              });
            }
          );
        },
      });
    },

    openSupportTicket(data) {
      this.$http
        .put(`contactUs/${data.id}`, {
          done: true,
        })
        .then(
          (response) => {
            this.getData();
            this.$toast.add({
              severity: "success",
              summary: "Success",
              detail: "Data Added Successfully",
              life: 3000,
            });
          },
          (err) => {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: err.response.data.message,
              life: 3000,
            });
          }
        );
    },
  },
  created() {
    this.getData();
    this.selectedColumns = this.columns;
  },
};
</script>

<style></style>
